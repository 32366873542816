






import { Component, Vue, Prop } from 'vue-property-decorator'
import { mixin as clickaway }   from '@/plugins/vue-clickaway.ts'

import DocumentViewer from '@/components/document/Viewer.vue'

import { DocumentResource } from '@/models/documents/DocumentResource'

@Component({
  mixins: [clickaway],
  components: {
    DocumentViewer
  }
})
export default class DocumentEditor extends Vue {

  // Props
  @Prop({ required: true })
  private document!: DocumentResource

  @Prop({ required: false, default: false })
  private showActions!: boolean

  @Prop({ required: false, default: false })
  private fontBigger!: boolean

  // Data
  private showChanges: boolean = false

}

